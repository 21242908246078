<template>
    <div :class="{mobile: isMobile}">
        <div class="top mb-7">
            <div class="title">{{ $t("scroe_task_center") }}</div>
            <!-- <div class="right">
                <div class="points">
                    <div class="num1">{{$t("pointsCenter_exchange")}} {{currentPoints.toFixed(6)}}</div>
                    <div class="progress" :style="{width:progressWidth+'px'}">
                        <div class="cg" :style="{width:currentProgress+'px'}"></div>
                    </div>
                    <div class="num2">{{ $t("pointsCenter_num2") }}：{{scoreLimit}} {{ $t("pointsCenter_Points") }}  ({{scoreLimit*scoreRate}} UPTICK)</div>
                </div>
                <div v-if="currentPoints>=scoreLimit" class="btn-claim" @click="exchange"><span>{{ $t("admin_activity_btn_claim") }}</span></div>
                <div v-else class="btn-claim btn-claim-grey"><span>{{ $t("admin_activity_btn_claim") }}</span></div>
                 <img src="@/assets/icons/icon_claimhistory.png" @mouseenter="historymoused()" @mouseleave="historyleave()" width="23" height="23" class="ml-4" alt="" @click="toHistroy">
                <div class="showHistroy d-flex flex-row align-center" v-if="isShowHistroy" >Claim History</div>
            </div> -->
        </div>
        <div class="number">
            <div class="item">
                <div class="back">
                    <span class="s1">{{ $t("scroe_task_title") }}</span>
                    <span class="s2">{{totalScore}}</span>
                </div>
            </div>
            <!-- <div class="item">
                <div class="back">
                    <span class="s1">{{ $t("scroe_task_consumer") }}</span>
                    <span class="s2">{{scroe_task_consumer}}</span>
                </div>
            </div>
            <div class="item">
                <div class="back">
                    <span class="s1">{{ $t("scroe_task_creator") }}</span>
                    <span class="s2">{{scroe_task_creator}}</span>
                </div>
            </div> -->
        </div>
        <div class="list">
            <div class="row" v-for="(item, idx) in list" :key="idx">
                <span class="item s1">{{item.actionDisplay}}</span>
                <span class="item s2">{{timestampToDateTime(item.createTime)}}</span>
                <span class="item s3">{{item.score>0?'+':''}}{{item.score}}</span>
            </div>
        </div>
        <loading :isShowLoading="isShowLoading" ></loading>

        <promptBox ref="confirm" @commit="exchangeConfirm"></promptBox>
        <uToast ref="toast"></uToast>
    </div>
</template>

<script>
    import api from "@/api";
    import Loading from "@/components/loading.vue";
        import { getUptickAddress } from "../../metaMask/evm/index";
    
    export default {
        components: { Loading },
        data() {
            return {
                // scroe_task_title: 0,
                scroe_task_consumer: 0,
                scroe_task_creator: 0,

                list: [],
                pageNumber: 1,
                totalPage: 0,
                
                isShowLoading: false,
                loadingTimer: 0,

                totalScore: 0,
                scoreLimit: 1,
                currentPoints: 0,
                currentProgress: 0,

                scoreRate: 0,
                progressWidth: 0,
                 isShowHistroy:false,
                 uptickAddress:''
            }
        },
        computed: {
            isMobile: function() {
                let mobile = this.$vuetify.breakpoint.mobile;
                return mobile;
            }
        },
        mounted() {
            window.eventBus.$on('LangChange', this.onLangChange);
            window.onscroll = this.onScroll;
            
            this.progressWidth = this.isMobile ? 240 : 403;

            this.init();
        },
        methods: {
            async init() {
                let res1 = await api.home.loopringUserInfo({
                    address: this.$store.state.did
                });
                this.totalScore = parseFloat(res1.data.totalScore || 0);

                // let res2 = await api.home.scoreReward({
                //     userAddress: this.$store.state.did,
                //     action: "BUY"
                // });
                // this.scroe_task_consumer = parseFloat(res2.data.score || 0);

                // let res3 = await api.home.scoreReward({
                //     userAddress: this.$store.state.did,
                //     action: "MINT"
                // });
                // this.scroe_task_creator = parseFloat(res3.data.score || 0);

                let res4 = await api.home.getSystemParams({keys:"ScoreExchangeLimit"});
                let res5 = await api.home.getSystemParams({keys:"ScoreExchangeRate"});
                if(res4.data)
                this.scoreLimit = parseFloat(res4.data[0].value || 0);
             if(res5.data)
                this.scoreRate = parseFloat(res5.data[0].value || 0);

                 this.uptickAddress =  await getUptickAddress(res1.data.address)
            console.log("uptickAddress",this.uptickAddress );
                this.currentPoints = parseFloat(res1.data.currentScore || 0);

                this.getList();

                this.currentProgress = this.progressWidth / this.scoreLimit * this.currentPoints;
            },
            async getList() {
                let res4 = await api.home.scoreRecord({
                    userAddress: this.$store.state.did,
                    pageSize: 10,
                    pageNumber: this.pageNumber
                });
                this.list = [...this.list, ...res4.data.list];
                this.totalPage = res4.data.totalPage;
            },
            onScroll() {
                let scrollTop = document.documentElement.scrollTop||document.body.scrollTop;
                let windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
                let scrollHeight = document.documentElement.scrollHeight||document.body.scrollHeight;
                
                if (!this.isShowLoading && scrollTop + windowHeight >= scrollHeight && this.totalPage > this.pageNumber) {
                    clearTimeout(this.loadingTimer);
                    this.loadingTimer = setTimeout(() => {
                        this.isShowLoading = true;
                        this.pageNumber++;
                        this.getList();
                    }, 400);
                } else {
                    this.isShowLoading = false;
                }
            },
            onLangChange() {
                this.list = [];
                this.pageNumber = 1;
                this.getList();
            },
             historymoused(){
                this.isShowHistroy = true
                },
                 historyleave(){
                    this.isShowHistroy = false

                },
                toHistroy(){
                    this.$router.push({name:'ClaimHistroy'})
                },
            exchange() {
                this.$refs.confirm.show(this.$t("pointsCenter_exchange")+" "+this.currentPoints+" "+this.$t("pointsCenter_Points")+" ");
            },
            async exchangeConfirm() {
                let res1 = await api.home.scoreExchange({
                     address: this.$store.state.did,
                     receiveAddress:this.uptickAddress
                });
                this.$refs.toast.show("success", res1.msg);
                this.init();
            }
        }
    }
</script>

<style lang="scss" scoped>
    .top {
        display: flex;
        margin-bottom: 10px;
        .title {
            width: 300px;
            font-weight: bold;
            // height: 21px;
            font-weight: bold;;
            font-size: 25px;
            font-stretch: normal;
            letter-spacing: 0px;
            color: #270645;
        }
        .right {
             position: relative;
            margin-left: auto;
            display: flex;
            align-items: center;
            .points {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                .num1 {
                    font-weight: bold;;
                    font-size: 14px;
                    color: #924eff;
                }
                .num2 {
                    font-family: Helvetica;
                    font-size: 12px;
                    color: #270645;
                }
                .progress {
                    width: 403px;
                    height: 6px;
                    background-image: linear-gradient(
                        #766983, 
                        #766983), 
                    linear-gradient(
                        #f1f0f2, 
                        #f1f0f2);
                    background-blend-mode: normal, 
                        normal;
                    border-radius: 3px;
                    margin: 3px 0px;
                    overflow: hidden;
                    .cg {
                        height: 6px;
                        background-image: linear-gradient(90deg, 
                            #d300fd 0%, 
                            #a439fe 26%, 
                            #7471ff 52%, 
                            #00fdcf 100%), 
                        linear-gradient(
                            #f1f0f2, 
                            #f1f0f2);
                        background-blend-mode: normal, 
                            normal;
                        border-radius: 3px;
                    }
                }
            }
               .showHistroy{
                    position: absolute;
                    z-index: 100;
                    top: 48px;
                    right: 0;
                    width: 110px;
                    height: 41px;
                    background-color: #270645;
                    border-radius: 3px;
                	font-family: Helvetica;
                    font-size: 12px;
                    font-weight: normal;
                    font-stretch: normal;
                    line-height: 20px;
                    letter-spacing: 0px;
                    color: #ffffff;
                    justify-content: center;
            }
            .btn-claim {
                width: 121px;
                height: 41px;
                background-color: #270645;
                border-radius: 20px;
                text-align: center;
                margin-left: 15px;
                user-select: none;
                cursor: pointer;

                span {
                    font-weight: bold;;
                    font-size: 15px;
                    line-height: 41px;
                    color: #ffffff;
                }
            }
            .btn-claim-grey {
                background-color: #766983;
                cursor: unset !important;
            }
        }
    }
    

    .number {
        display: flex;
        justify-content: space-between;
        height: 130px;

        .item {
            width: 391px;
            height: 116px;
          
   
            border-radius: 18px;
            position: relative;

            .back {
                position: absolute;
                left: 2px;
                top: 2px;
                right: 2px;
                bottom: 2px;
                background-color: #FFFFFF;
                border-radius: 18px;
                border:2px solid #1d42ff;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                .s1 {
                    font-weight: bold;;
                     font-weight: bold;
                    font-size: 14px;
                    color: #270645;
                }

                .s2 {
                    font-weight: bold;;
                     font-weight: bold;
                    font-size: 25px;
                    color: #1d42ff;
                }
            }
        }
    }

    .list {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 50px;

        .row {
            width: 100%;
            height: 61px;
            background-image: linear-gradient(#ffffff,
                    #ffffff),
                linear-gradient(#6f58d9,
                    #6f58d9);
            background-blend-mode: normal,
                normal;
            box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.09);
            border-radius: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 20px;

            .s1 {
                width: 43%;
                max-width: 43%;
                font-weight: bold;;
                font-size: 14px;
                color: #270645;
                font-weight: bold;
            }

            .s2 {
                width: 43%;
                max-width: 43%;
                font-family: Helvetica;
                font-size: 12px;
                color: #270645;
            }
            .s3 {
                width: 3%;
                max-width: 3%;
                font-weight: bold;;
                font-size: 14px;
                font-weight: bold;
                color: #1d42ff;
            }
        }
    }

    .mobile {
        margin-top: -20px;
        .top {
            flex-direction: column;
            margin-bottom: 10px !important;
            .btn-claim {
                width: 100px;
            }
        }
        
        .title {
            font-weight: bold;;
            font-size: 15px;
            color: #270645;
            line-height: 80px;
        }

        .number {
            height: 80px;

            .item {
                width: 115px;
                height: 65px;

                .back {
                    .s1 {
                        font-size: 9px;
                        white-space: nowrap;
                    }

                    .s2 {
                        font-size: 14px;
                    }
                }
            }
        }

        .list {
            .row {
                width: 100%;
                padding: 0 5px;

                .s2 {
                    width: 40%;
                }
                
                .s3 {
                    width: 15%;
                    max-width: 15%;
                    text-align: right;
                }
                
            }
        }
    }
</style>
